.allequipe{
    width: 100vw;
    main{
        width: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 100px;
        .top{
           text-align: center;
           display: flex;
           justify-content: center;
           align-items: center;
           flex-direction: column;
           h1{
            font-size: 2rem;
           }
           p{
            width: 80%;
            margin-top: 40px;
           }
        }
        .teamPush{
            width: 95%;
            display: grid;
            grid-template-columns: repeat(auto-fit,550px);
            justify-content: center;
            gap: 30px;
            padding-bottom: 50px;
            margin-top: 100px;
            .equipeSelf{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border: 1px solid gray;
                padding: 10px;
                padding-right: 20px;
                padding-bottom: 10px;
                .left{
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    overflow: hidden;
                }
                .right{
                    padding-bottom: 15px;
                   text-align: center;
                   margin-top: 20px;
                   .social{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 0;
                   a{
                    text-decoration: none;
                    color: #fff;
                    i{
                        
                        border: 1px solid $linear-color;
                        font-size: 2em;
                        margin: 10px;
                        cursor: pointer;
                        background: transparent;
                        padding: 10px;
                        border-radius: 50%;
                        &:hover{
                            background: $linear-color;
                            color: #fff;
                        }
                    }
                   }
                }
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .integration{
            width: 100vw;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding-bottom: 80px;
            padding-top: 80px;
            h1{
                font-size: 1.9rem;
                width: 90%;
            }
            p{
                width: 90%;
                margin-top: 20px;
                padding-bottom: 20px;
            }
            a{
                background: $linear-color;
                padding: 10px 20px;
                color: #fff;
            }
            .soumis{
                margin-top: 50px;
                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    a{
                        margin: 20px;
                        border-radius: 50%;
                        padding: 0;
                        background: transparent;
                        border: 1px solid rgb(199, 199, 199);
                        i{
                            font-size: 2rem;
                            padding: 10px;
                            color: red;
                        }
                        .bxl-whatsapp{
                            color: #00c300;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width:900px){
    .allequipe{
        main{
            .teamPush{
                grid-template-columns: repeat(auto-fit,95%);
            }
        }
    }
    
}
@media screen and (max-width:600px){
    .allequipe{
        main{
            .teamPush{
                grid-template-columns: repeat(auto-fit,95%);
                .equipeSelf{
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding-top: 20px;
                    .right{
                        text-align: center;
                    }
                }
            }
        }
    }
    
}