.navigation{
    width: 100vw;
    height: 80px;
    position: fixed;
    top: 0;
    display: flex;
    
    align-items: center;
    justify-content: space-between;
    z-index: 200;
    background: $bg-color;
    .logo{
        margin-left: 5%;
        margin-top: -15px;
        height: 80px;
        width: 80px;
        padding: 10px;
        // overflow: hidden;
        border-radius: 50%;
        img{
            height: 100%;
            width: 100%;
            -webkit-object-fit:cover;
            object-fit: cover;
            background: #fff;
            padding: 7px;
            border-radius: 50%;  
       }
    }
    .openMenu{
        font-size: 35px;
        cursor: pointer;
        display: none;
        margin-right: 5%;
        color: $link-color;
    }
    .menu{
        margin-right: 5%;
        display: flex;
        align-items: center;
        .closeMenu{
            color: $link-color;
            font-size: 40px;
            position: absolute;
            top: 20px;
            right: 5%;
            cursor: pointer;
            display: none;
        }
        a{
            text-decoration: none;
            color: $link-color;
            font-size: $main-font-size;
            text-transform: capitalize;
            padding: 5px;
            margin-left: 10px;
        }
    }
}
.navigation .menu.active{
    width: 100vw;
}

@media screen and (max-width:780px) {
    .navigation{
        .logo{
            height: 80px;
            width: 80px;
        }
        .openMenu{
            display: block;
        }
        .menu{
            flex-direction: column;
            position: fixed;
            height: 100vh;
            width: 0;
            overflow: hidden;
            margin-right: 0;
            background: #000000;
            right: 0;
            top: 0;
            justify-content: center;
            transition: width .2s;
            z-index: 200;
            .closeMenu{
                display: block;
            }
            a{
                font-size: 25px;
                transition: transform .3s;
                &:hover{
                    transform: scale(1.3);
                }
            }
        }
    }
}