.about{
    main{
        .historique{
            display: grid;
            grid-template-columns: repeat(auto-fit,45%);
            justify-content: center;
            padding-left: 20px;
            margin-top: 50px;
            .left{
                .top{
                    width: 100%;
                    padding: 50px;
                    display: flex;
                    border-radius: 50%;
                    justify-content: center;
                    img{
                        width: 50%;
                        height: 50%;
                        object-fit: contain;
                    }
                }
            }
            .right{
                padding-left: 20px;
                border-left: 1px solid tan;
                margin-left: 10px;
            }
        }
        .capacity{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 50px;
            margin-top: 50px;
            h1{
                margin-left : 20px;
                padding-bottom: 30px;
            }
            ul{
               margin-top: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: 1px solid tan;
                li{
                    width: 90%;
                    padding: 10px;
                    list-style-type: circle;
                    list-style-position: inside;
                    span{
                        font-size: 1em;
                        font-weight: 900;
                        font-family: michroma;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:900px){
    .about{
        main{
            .historique{
                grid-template-columns: repeat(auto-fit,95%);
                .right{
                    padding: 0;
                    border: none;
                }
            }
        }
    }
}
@media screen and (max-width:500px){
    .about{
      main{
        .capacity{
            ul{
                width: 90%;
            }
          }
      }
    }
}