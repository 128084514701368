.contact{
    main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    .top{
        width: 90%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 50px;
        p{
            width: 80%;
            margin-top: 10px;
        }
        h1{
            width: 100%;
            margin-top: 50px;
            padding-bottom: 40px;
        }
    }
    .coords{
        width: 90%;
        display: grid;
        grid-template-columns: repeat(auto-fit,350px);
        justify-content: center;
        gap: 20px;
        div{
            padding: 10px;
            border: 1px solid rgb(44, 44, 44);
            h2{
                width: 98%;
                a{
                    color: rgb(255, 255, 255);
                }
            }
        }
        .localiser{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: $linear-color;
            h2{
                color: rgb(255, 255, 255);
            }
        }
    }
}
}
@media screen and (max-width:900px){
    .contact{
        main{
            padding: 0;
            .top{
                p{
                width: 95%;
                font-size: 20px;
                }
            }
            .coords{
                grid-template-columns: repeat(auto-fit,95%);
            }
        }
    }
}
@media screen and (max-width:500px){
    .contact{
        main{
            padding: 0;
            width: 100%;
            .coords{
                padding-bottom: 50px;
                grid-template-columns: repeat(auto-fit,98%);
            }
        }
    }
}
body.light .contact{
    main{
        .coords{
            div{
                h2{
                    a{
                        color: #000;
                    }
                }
            }
        }
    }
}