.blog{
    display: flex;
    flex-direction: column;
    .topBox{
        text-align: left;
        padding-top: 20px;
        margin-left: 5%;
    }
    .articlePush{
        padding: 50px 0;
        display: grid;
        grid-template-columns: repeat(auto-fit,450px);
        gap: 30px;
        justify-content: center;
        .article{
            background: rgb(255, 255, 255);
            border-radius: 20px;
            box-shadow: 2px 2px 20px 20px #00000011;
            .top{
                width: 100%;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    -webkit-object-fit: cover;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                }
            }
            .bottom{
                padding: 15px;
                padding-bottom: 0;
                p{
                    color: gray;
                    font-size: 0.9em;
                    &:nth-child(5){
                        margin-top: 10px;
                        color: rgb(74, 74, 74);
                    }
                }
                h3{
                    font-size: 20px;
                    color: #000;
                }
                a{
                    background: $linear-color;
                    color: #fff;
                    padding: 10px 20px;
                    &:hover{
                        filter: hue-rotate(45deg);
                    }
                }
            }
        }
    }
    .rapportPush{
        text-align: center;
        margin-top: 20px;
        padding-bottom: 50px;
        .rapport{
            margin-top: 20px;
            margin-bottom: 20px;

            h2{
                width: 80%;
                text-align: left;
                margin-left: 5%;
                padding-bottom: 20px;
            }
        }
    }
}
@media screen and (max-width:900px) {
    .blog{
        .articlePush{
            grid-template-columns: repeat(auto-fit,90%);
        }
    }
}