.projet{
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1{
        text-align: center;
        padding: 20px;
        margin-top: 80px;
    }
    .projectPush{
        display: grid;
        grid-template-columns: repeat(auto-fit,45%);
        gap: 20px;
        justify-content: center;
        padding-bottom: 50px;
        margin-top: 50px;
        .project{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.202);
            &:hover{
                .bottom{
                    height: 100%;
                    width: 103%;
                }
            }
            .top{
                height: 400px;
                width: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .bottom{
                position: absolute;
                bottom: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                background: #00000089;
                -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
                height: 0;
                left: -9px;
                width: 100%;
                overflow: hidden;
                transition: height .3s;
                cursor: pointer;
                border: épx solid;
                h1{
                    font-size: 1.2rem;
                    color: #f1f1f1;
                    margin-top: -40px;
                }
                p{
                    color: #fff;
                    font-size: 16px;
                    text-transform: capitalize;
                    width: 90%;
                }
            }
        }
    }
    .videoContainer{
        height: 550px;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 250px;
        margin-top: 100px;
        p{
            width: 80%;
            text-align: center;
        }
        video{
            height: 500px;
            width: 750px;
        }
    }
}

@media screen and (max-width:900px){
    .projet{
        .top{
            p,a{
                font-size: 23px;
            }
        }
        .projectPush{
            grid-template-columns: repeat(auto-fit,90%);
            .project{
                .bottom{
                    
                    p{
                        width: 80%;
                    }
                }
            }
        }
        .videoContainer{
            video{
                width: 500px;
                height: auto;
            }
        }
    }
}

@media screen and (max-width:500px) {
    .projet{
        .videoContainer{
            padding-bottom: 80px;
            margin-top: -50px;
            video{
                width: 350px;
                height: auto;
            }
        }
    }
}