.home{
    width: 100vw;
    margin-top: 80px;
    .header{
        height: 80vh;
    }
    .main{
        width: 100vw;
        display: flex;
        flex-direction: column;
        .about{
            min-height: 70vh;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 50px;
            .left{
                padding: 20px;
                width: 50%;
                h1{
                    font-size: $title-size;
                }
                p{
                    width: 90%;
                    margin-top: 40px;
                    ul{
                        margin-top: 20px;
                    }
                }
                .quoteDiv{
                    p.quote{
                        color:  #f1f1f1;
                       font-weight: bold;
                    }
                    h1{
                        font-family: singatures;
                    }
                    .signature{
                        font-family: signatures;
                        font-size: 3.5em;
                        margin-top: 20px;
                        color: rgb(0, 214, 252);
                    }
                }
                button{
                    margin-top: 40px;
                }
            }
            .right{
                width: 35%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        .acceuil,.devs,.marketing{
            margin-top: 50px;
            min-height: 40vh;
            padding-left: 5%;
            padding-bottom: 50px;
            .pTop{
                font-size: 27px;
            }
            h1{
                width: 80%;
            }
  
            p{
                width: 80%;
                padding-bottom: 40px;
                margin-top: 40px;
            }
            ul{
                margin-top: -30px;
               
            }
           
        }
        .demarche{
            position: relative;
            min-height: 50vh;
            text-align: center;
            background: url(../components/8.jpg) no-repeat;
            background-size: cover;
            .layer{
                background: rgba(0, 0, 0, 0.67);
                position: absolute;
                height: 100%;
                width: 100%;
            }
            .content{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: relative;
                padding-bottom: 50px;
                h1{
                    width: 70%;
                    margin-top: 20px;
                }
                p{
                    margin-top: 40px;
                    width: 80%;
                    color: $link-color;
                }
              .img{
                height: 150px;
                width: 150px;
                background: #fff;
                border-radius: 50%;
                overflow: hidden;
                margin-top: 50px;
                padding: 10px;
                img{
                    height: 100%;
                    width: 100%;
                    -webkit-object-fit:cover;
                    object-fit: contain;
                }
              }
            }
        }
        .confiance{
            padding-bottom: 50px;
            padding-top: 40px;
            .content{
                text-align: center;
                margin-bottom: 30px;
                h1{
                    padding-bottom: 30px;
                }
                .container{
                    background: transparent;
                    margin: 0 auto;
                    width: 100%;
                    padding: 40px 20px;
                    .item{
                        img{
                            width: 50%;
                            height: 50%;
                            object-fit: cover;
                            margin: 0 auto;
                        }
                        h2{
                            width: 100%;
                            height: 50%;
                            text-align: center;
                            margin-top: 40px;
                            font-size: 1.2em;
                            display: none;
                        }
                    }
                }
            }
        }
    
    }
 
}

@media screen and (max-width:900px) {
   .home{
    .header{
        height: 75vh;
    }
    .main{
        .about{
            flex-direction: column;
            align-items: center;
            // justify-content: center;
            .left{
                width: 100%;
                p{
                    margin-left: 5%;
                }
                h1{
                    margin-left: 5%;
                }
                .quoteDiv{
                        .signature{
                            font-size: 2em;
                    }
                }
            }
            .right{
                width: 85%;
                flex-direction: column;
            }
        }
        .acceuil,.devs,.marketing{
            h1{
                width: 90%;
                font-size: 1em;
            }
            p{
                width: 90%;
            }
        }
       
    }
   
   }
}
@media screen and (max-width: 500px) {
    .home{
        .main{
            .demarche{
                .content{
                    h1{
                        width: 95%;
                    }
                    p{
                        width: 90%;
                    }
                }
            }
            .confiance{
                .content{

                    h1{
                        padding-bottom: 30px;
                    }
                    .container{
                        margin: 0 auto;
                        width: 100%;
                        height: 100%;
                        padding: 40px 20px;
                        .item{
                            width: 5550px;   
                            img{
                                width: 100%;
                                height: 90%;
                            }
                            h2{
                             display: none;
                            }
                        }
                    }
                }
            }
            
        }
    }
}
@media screen and (max-width: 400px) {
    .home{
        .main{
            .acceuil,.devs,.marketing{
                h1{
                    width: 90%;
                    font-size: 1.4em;
                }
                p{
                    width: 90%;
                }
            }
            .demarche{
                .content{
                    h1{
                        width: 95%;
                    }
                    p{
                        width: 85%;
                    }
                }
            }
       
        }
    }
}
