@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

@font-face {
    font-family: signatures;
    src: url(../../public/font/signatures.otf);
}

$main-font-size:16px;
$title-size : 2em;
$main-family: nunito,sans-serif;
// $big-font: Playfair display;
$big-font: nunito;
$link-color: #f5f5f5;
$bg-color: #161927;
$tite-color: #f1f1f1;

$linear-color: linear-gradient(15deg,#026129,#00d231);
// $linear-color: linear-gradient(75deg,#d2b48c,wheat);
*{
    transition: background .3s , color .3s;
}
body,html{
    overflow-x: hidden;
    scroll-behavior: smooth;
    h1,h2{
        font-weight: 900;
    }
    p{
        font-family: $main-family;
    }
}

body.dark{
    margin: 0;
    padding: 0;
    font-family: $main-family;
    box-sizing: border-box;
    background: $bg-color;
    color: #ffffff;
    h1,h2{
        font-family: $big-font;
    }
    h1{
        color: $tite-color;
    }
    h2{
        font-size: 1.2em;
    }
 
    p{
        color: #ededed;
        font-size:  $main-font-size;
    }
    li{
        font-size: 16px;
        width: 80%;
    }
    a{
        text-decoration: none;
    }

   
}
span{
    color:  #026129;
}
body.light{
    $link-color: #090909;
    $tite-color: #010101;
    $bg-color: #ffffff;
    background: $bg-color;
    color: #464646;
    margin: 0;
    padding: 0;
    font-family: $main-family;
    box-sizing: border-box;
    h1,h2{
        font-family: $big-font;
        // font-size: 1em;
    }
    h1{
        color: $tite-color;
    }
    h2{
        font-size: 1.2em;
        color: $tite-color;
    }
    p{
        color: #2d2d2d;
        font-size: $main-font-size;
    }
   
    li{
        font-size: 16px;
        width: 80%;
    }
    a{
        text-decoration: none;
    }
    .form{
        .formulaire{
            textarea,input[type=email]{
                color: #000;
            }
        }
    }
    .navigation{
        background: #ffffff;
        box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.096);
        .openMenu{
            color: rgb(45, 45, 45);
        }
        .menu{
            .closeMenu{
                color: rgb(45, 45, 45);
            }
            a{
                color: rgb(45, 45, 45);
            }
            #darkMode{
                i{
                    color: rgb(45, 45, 45);
                }
            }
        }
    }
    .header{
        .layer{
            background: rgba(0, 0, 0, 0.785);
            -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
        }
        .content{
            p{
                color: #e7e7e7;
            }
            .headerTitle{
                color: #ffffff;
            }
        }
    }
    .equipe,.allequipe{
        .teamPush{
            .equipeSelf{
                box-shadow: 1px 1px 10px 10px #00000005;
                border: none;
                .right{
                    .social{
                        color: #000;
                        a{
                            color: #000;
                            i{
                                border: 1px solid rgb(225, 224, 224);
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
    }
    .footer{
      .bottom{
        .left{
            p{
                a{
                    color: $tite-color;
                }
            }
          }
          .right{
            a{
                color: $tite-color;
            }
          }
      }
      .foot{
        background: #f2f2f2;
        p{
            color: $tite-color;
        }
    }
    }
    .home{
        .main{
            .about{
                .left{
                    .quoteDiv{
                        .quote{
                            color: #000;
                        }
                    }
                }
            }
            .demarche{
                .content{
                    h1{
                        color: #fff;
                    }
                }
            }
            .confiance{
                .content{
                    .container{
                        background: transparent;
                    }
                }
            }
        }
    }
    @media screen and (max-width:780px) {
        .navigation{
            .menu{
                background: #fff;
            }
        }
    }
}

::-webkit-scrollbar{
    width: 5px;
    background: transparent;
}
::-webkit-scrollbar-thumb{
    background: $linear-color;
    border-radius: 20px;
}

button,.btn{
    padding: 10px 50px;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    background: $linear-color;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
    &:hover{
       filter: hue-rotate(25deg);
    }
}

@media screen and (max-width:500px){
    $title-size : 1.8rem;
   $main-font-size: 16px;
}
