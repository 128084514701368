.statistique{
    background: #141414;
    min-height: 35vh;
    padding-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit,200px);
    justify-content: center;
    align-items: center;
    gap: 30px;
    color: #fff;
    .stat{
        text-align: center;
        i{
            font-size: 2em;
        }
        p{
            color: #fff;
        }
        h1{
            color: #f5f5f5;
        }
    }
}