.form{   
    background: transparent;
    margin-top: 50px;
    padding: 10px 30px;
    border: 1px solid #fff;
    border-radius: 10px;
    
      
    .formulaire{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        input{
            margin-top: 20px;
            width: 80%;
        }
        input[type=email],input[type=text]{
            background-color: rgba(228, 228, 225, 0.132);
            color: #fff;
            border: none;
            border-radius: 10px;
            outline: none;
            padding: 10px;
            width: 300px;
        }
        textarea{
            height: 100px;
            background-color: rgba(228, 228, 225, 0.132);
            border: none;
            outline: none;
            border-radius: 10px;
            color: #fff;
            padding: 10px;
            width: 300px;
            resize: none;
            margin-top: 20px;
        }
        input[type=submit]{
            outline: none;
            border: none;
            background: $linear-color;
            color: #fff;
            padding: 10px;
            width: 200px;
            border-radius: 10px;
            cursor: pointer;
            &:hover{
                filter: hue-rotate(25deg);
             }
        }
    }
    
}
@media screen and (max-width:500px) {
    .form{
        .popup{
            width: 90vw;
        }
    }
    
}