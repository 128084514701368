.header{
    position: relative;
    height: 70vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // background: url(./1.gif) no-repeat;
    // background-size: cover;
    // background-position: center;
    .backgroundVideo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; /* Pour que la vidéo couvre tout l'arrière-plan */
        z-index: -1; /* Mettre la vidéo en arrière-plan */
    }
    .layer{
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.462);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        z-index: 10;
    }
   .content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 20;
    
    .headerTitle{
        color: $tite-color;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        font-size: 3rem;
    }
    p{
        margin-top: 15px;
        color: #fff;
        width: 90%;
        text-align: center;
    }
   }
}
@media screen and (min-width:900px) {
    .header{
        .content{
            .headerTitle{
                font-size: 3em;
            }
        }
    }
}
@media screen and (max-width:780px) {
    .header{
        height: 90vh;
        .content{
            margin-top: 50px;
            .headerTitle{
                // font-size: 2em;
                padding-bottom: 20px;
            }
        }
    }
}
@media screen and (max-width:500px) {
    .header{
        height: 90vh;
        .content{
            margin-top: 100px;
            .headerTitle{
                font-size: 2.5em;
                padding-bottom: 20px;
            }
        }
    }
}