#darkMode{
    i{
            text-decoration: none;
            color: $link-color;
            font-size: 1.5rem;
            cursor: pointer;
            text-transform: capitalize;
            padding: 5px;
            margin-left: 10px;
    }
}