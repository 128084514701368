.recent{
    color: #f1f1f1;
    margin-top: 80px;
    padding-bottom:30px;
    .top{
        display: flex;
        justify-content: space-between;
        margin-right: 5%;
        p,a{
            display: flex;
            align-items: center;
            font-size: 20px;
            margin-left: 5%;
            cursor: pointer;
            display: flex;
            color: #00d231;
            .bx{
                font-size: 1.5em;
            }
        }
        a:hover{
            filter: hue-rotate(15deg);
        }
    }
    .worksPush{
        display: grid;
        grid-template-columns: repeat(auto-fit,370px);
        gap: 20px;
        justify-content: center;
        padding-left: 10px;
        .work{
            height: 300px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &:hover{
                .bottom{
                    height: 100%;
                    width: 100%;
                }
            }
            .top{
                height: 400px;
                width: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .bottom{
                position: absolute;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                background: #00000089;
                -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
                height: 0;
                left: -9px;
                width: 101%;
                overflow: hidden;
                transition: height .3s;
                cursor: pointer;
                h1{
                    font-size: 1.3rem;
                    color: #f1f1f1;
                }
                p{
                    color: #fff;
                    font-size: 18px;
                    line-height: 30px;
                    text-transform: capitalize;
                }
            }
        }
    }
}

@media screen and (max-width:800px){
    .recent{
        .top{
            p,a{
                font-size: 23px;
            }
        }
        .worksPush{
            grid-template-columns: repeat(auto-fit,90%);
            .work{
                .bottom{
                    
                    p{
                        width: 80%;
                    }
                }
            }
        }
    }
}