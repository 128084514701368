.footer {
    min-height: 30vh;
    width: 100vw;
    padding-top: 50px;
    .top {
    padding-left: 5%;

        h1 {
            font-size: 5em;
            text-align: left;
        }
    }

    .bottom {
        padding-left: 5%;
        width: 100vw;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-bottom: 40px;
        p {
            display: flex;
            align-items: center;
            a {
                color: $link-color;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            font-size: 20px;
        }

        .left {
            p.miniText{
                width: 95%;
                display: flex;
                align-items: center;
                font-size: 18px;
                color: #ffffff;
                background: #212121;
                padding: 10px;
                i{
                    font-size: 2em;
                }
            }
        }

        .right {
            margin-right: 5%;
            display: flex;
            flex-direction: column;
            align-items: center;
            
            .links{
                margin-top: 30px;
                a {
                    padding: 10px;
                    font-size: 25px;
                    text-decoration: none;
                    color: $link-color;
                    i{
                        font-size: 1.5em;
                    }
                    &:hover {
                        color: gray;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .foot {
        width: 100vw;
        background-color: #222222;
        display: flex;
        justify-content: space-between;

        p {
            margin-right: 50px;
            padding-top: 5px;
            font-size: 14px;

            &:nth-child(1) {
                margin-left: 50px;
            }

            .bxs-heart {
                color: red;
            }

            a {
                color: rgb(0, 214, 252);
                text-decoration: underline;

                &:hover {
                    filter: hue-rotate(45deg);
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .footer {
        .top {
            h1 {
                font-size: 3.5em;
            }
        }

        .bottom {
            flex-direction: column;

            .right {
                margin-left: 5%;
            }
        }

        .foot {
            p {
                font-size: 12px;
                
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .footer {
        text-align: left;
        padding-left: 2%;
        .bottom {
            display: block;
            padding-left: 10px;

            .left {
                a {
                    font-size: 18px;
                    // margin-left: -10px;
                }
              
            }

            .right {
                margin: 0;
                padding-left: 0;
                margin-top: 0px;
                width: 100%;
                padding-bottom: 40px;
            }
        }

        .foot {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 10px;
            p {
                margin: 0;
                padding: 0;
                &:nth-child(1) {
                    text-align: center;
                    width: 80%;
                    margin-top: 10px;
                    margin-left: 0;
                }
            }
        }
    }
}
