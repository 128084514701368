.services{
    
    main{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 50px;
        padding-bottom: 50px;
        flex-direction: column;
     .topdiv{
        margin-top: 50px;
        display: flex;
        justify-content: center;
        h1{
            text-align: center;
            width:  90%;
            font-size: 2em;
        }
     }
        .servicesPush{
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit,250px);
            justify-content: center;
            padding-left: 5%;
            padding-right: 5%;
            gap: 25px;
            margin-top: 50px;
            .service{
                background: #fff;
                box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.058);
                display: flex;
                flex-direction: column;
                cursor: pointer;
                &:hover{
                    background: $bg-color;
                    transition: .3s ease;
                    .top{
                        background: rgb(179, 179, 179);
                    }
                    .bottom{
                        p{
                            color: #f1f1f1;
                        }
                    }
                }
                .top{
                    background: rgb(242, 242, 242);
                    height: 150px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    img{
                        width: 100px;
                    }
                }
                .bottom{
                    p{
                        font-size: 15px;
                        padding: 10px;
                        width: 100%;
                        text-align: center;
                        color: rgb(67, 67, 67);
                    }
                }
            }
        }
    }
}

@media screen and  (max-width:500px){
    .services{
        main{
            .servicesPush{
                grid-template-columns: repeat(auto-fit,90%);
            }
        }
    }
}