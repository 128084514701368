.equipe{
    color: #fff;
    padding-bottom:80px;
    margin-top: 50px;
    .top{
        display: flex;
        justify-content: space-between;
        margin-right: 5%;
        p,a{
            display: flex;
            align-items: center;
            font-size: 20px;
            margin-left: 5%;
            cursor: pointer;
            display: flex;
            color: #00d231;
            .bx{
                font-size: 1.5em;
            }
        }
        a:hover{
            filter: hue-rotate(15deg);
        }
    }
    .teamPush{
        display: grid;
        grid-template-columns: repeat(auto-fit,350px);
        gap: 30px;
        justify-content: center;
        margin-top: 50px;
        .equipeSelf{ 
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            border: 1px solid #5c5a5a;
            }
            .top{
                height: 320px;
                width: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .bottom{
                text-align: center;
                width: 100%;
                h1{
                    // padding-left: 20px;
                    font-size: 2em;
                    margin-top: 10px;
                }
                p{
                    // padding-left: 20px;
                    margin-top: 15px;
                    font-size: 20px;
                    // margin-top: -10px;
                    text-transform: capitalize;
                }
                .social{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 0;
                   a{
                    text-decoration: none;
                    color: #f1f1f1;
                    i{
                        border: 1px solid gray;
                        font-size: 1.3em;
                        margin: 10px;
                        cursor: pointer;
                        background: transparent;
                        padding: 10px;
                        border-radius: 50%;
                        &:hover{
                            border: 1px solid transparent;
                            background: $linear-color;
                        }
                    }
                   }
                }
            }
        }
    }
body.light{
    .equipe{
        .teamPush{
            .equipeSelf{
                .bottom{
                    .social{
                        a{
                            color: #999999;
                            i{
                                 border: 1px solid #d6d6d6;
                                 &:hover{
                                    border: 1px solid transparent;
                                    background: $linear-color;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:400px){
    .equipe{
        .teamPush{
            grid-template-columns: repeat(auto-fit,90%);
        }
    }
}